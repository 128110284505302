import React from "react";

import useProfile from "../useProfile";

export default function useBadgeRulesProgress({ id }) {
  const { profile } = useProfile();

  const badgeRulesProgress = React.useMemo(() => {
    if (!id || !profile) return [];

    const badge = profile.badgeProgress.find(({ badgeId }) => badgeId === id);

    if (!badge?.badgeRules) return [];

    return badge.badgeRules.map((rule) => ({
      ...rule,
      completionPercentage: Math.min(
        (rule.currentScore / rule.scoreNeeded) * 100,
        100,
      ),
      isComplete: rule.currentScore >= rule.scoreNeeded,
    }));
  }, [id, profile]);

  return badgeRulesProgress;
}
